export default [

  {
    title: 'Orders',
    route: 'home',
    icon: 'ShoppingCartIcon',
  },

  {
    title: 'Clients & Scans',
    route: 'scans',
    icon: 'SmileIcon',
  },
  
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserIcon',
  },
  
  

]
